
  import { defineComponent, computed, onMounted, watch } from "vue";
  import { Form, Field, ErrorMessage } from "vee-validate";
  import ApiService from "@/core/services/ApiService";
  import { useBus } from '@/bus';
  import $ from 'jquery';
  import 'datatables.net';
  import 'datatables.net-bs4/css/dataTables.bootstrap4.min.css';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { VueCookieNext } from "vue-cookie-next";
 
export default defineComponent({
    name: "Task",
    components: {
      Form,
      Field,
      ErrorMessage,
      Datatable,
    },
    data() {
      return {
        loading: false,
        taskList: [] as any,
        initData:[] as any,
        taskKeyUpdate: 0,
        search: '',
        componentKey:0,
        user:[] as any,
        employee_id: '' as any,
        role_id:'' as any,
        all_cookies: '' as any,
        tableHeader: [
          {
            name: 'TASK No.',
            key: 'index',
          },
          {
            name: 'UPAZILLA',
            key: 'upazila_name',
          },
          {
            name: 'GRID',
            key: 'grid_name',
          },
          {
            name: 'DEADLINE',
            key: 'deadline',
          },
          {
            name: 'ASSIGNED BY',
            key: 'assigned_by',
          },
          {
            name: 'ASSIGNING DATE',
            key: 'assigning_date',
          },
          {
            name: 'FIRST UPLOADING DATE',
            key: 'first_uploading_date',
          },
          {
            name: 'LAST MODIFICATION DATE',
            key: 'last_modification_date',
          },
          {
            name: 'CONFIRMATION DATE',
            key: 'confirmation_date',
          },
          {
            name: 'Actions',
            key: 'actions',
          },
        ]
      };
    },
    async created() {
      await this.getTaskInfo();
      // $(this.$refs.taskList).DataTable({
      //   paging:true,
      //   pageLength: 50,
        
      // });
      this.initData= this.taskList;
    },
    methods: {
      async getTaskInfo() {
        this.loading=true;
        this.user= VueCookieNext.getCookie("_seip_employee_id");
        this.employee_id= VueCookieNext.getCookie("_seip_employee_id");
        this.role_id= VueCookieNext.getCookie("_seip_role_id");
        console.log(this.employee_id);
        await ApiService.get("configurations/task/info")
          .then((response) => {
            this.taskList = response.data.data;
            this.taskKeyUpdate+=1;
            console.log(this.taskList);
            this.loading=false
          })
          .catch(({ response }) => {
            console.log(response);
          });
      },
      taskView(taskDetail){
        const { bus } = useBus();
        let value = {
          taskDetail: taskDetail,
        };
        bus.emit('task-data-view', value);
      },
      taskEdit(task){
        const { bus } = useBus();
        let value = {
          task: task,
        };
        bus.emit('task-data-edit', value);
      },
      taskUpload(task){
        const { bus } = useBus();
        let value = {
          task: task,
        };
        bus.emit('task-data-upload', value);
      },

      filterTasks() {
        if(this.search=='')
        {
          this.taskList= this.initData;
          this.taskKeyUpdate +=1;
        }
        const tasks = this.taskList.filter(task => {
          return (
            task.deadline.toLowerCase().includes(this.search.toLowerCase()) ||
            task?.upazila_info?.upazila_name_eng.toLowerCase().includes(this.search.toLowerCase()) ||
            task?.grid_info?.grid_name.toLowerCase().includes(this.search.toLowerCase()) ||
            task?.employee_info?.name.toLowerCase().includes(this.search.toLowerCase())
          );
        });

        this.taskList= tasks;
        this.taskKeyUpdate +=1;
      },
    },
  });
  